import Vue from 'vue'
import App from './App.vue'
import router from './router'

/* STYLESHEETS */
import './themes/global.scss'
import './themes/resp.scss'
import './fonts/stylesheet.css'


import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

import VueAgile from 'vue-agile'
Vue.use(VueAgile)


import i18n from "./i18n";
import './registerServiceWorker'

const lang = localStorage.getItem("lang") || "en";
document.documentElement.lang = lang;

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
