<template>
  <header>
    <div class="row mob-menu" id="mob-menu" v-if="isMenuOpen" 
      data-aos="fade-right"
      data-aos-anchor="#mob-menu"
      data-aos-delay="160"
      data-aos-duration="1000"
     >


      <div class="px-3 mob-menu-head">
        <div class="btns d-flex">
          <div class="col-6 mt-5 px-2">
            <img src="../assets/img/icon_close.svg" alt="close menu" draggable="false" class="ms-3" @click="closeMenu">
          </div>
          <div class="col-6 text-end lang mt-5 px-2">
            <button  @click="toEng()">EN</button ><span class="mx-2">/</span><button class="me-3" @click="toIta()">IT</button>
          </div>
        </div>

        <div class="col-12 text-center">
          <svg width="56" height="58" viewBox="0 0 56 58" fill="none">
            <path d="M22.6135 25.54V28.1805V42.4302C22.6135 44.0691 22.6135 45.8445 22.6599 47.711C22.7064 49.5775 22.5206 51.1257 22.242 52.4002C21.8705 54.0391 21.4526 55.0863 20.9418 55.5869C20.3846 56.0879 19.4559 56.5885 18.1093 57.1804C17.3664 57.4991 16.7163 57.7267 16.252 57.7724V58H39.6549V57.7724C39.4227 57.6814 39.144 57.5901 38.8192 57.5447C38.5405 57.4537 38.1691 57.3624 37.7512 57.1804C36.4046 56.6342 35.4758 56.0879 35.0114 55.5869C34.5008 55.0863 34.1291 54.0391 33.7577 52.4002C33.479 51.171 33.2933 49.6232 33.3399 47.711C33.3864 45.8445 33.3864 44.0691 33.3864 42.4302V15.752V13.6123V10.7441V7.96705C33.3864 7.10206 33.4329 6.41917 33.5255 5.91838C33.7112 4.78026 33.9434 3.91524 34.2686 3.41446C34.5935 2.86816 35.0579 2.4129 35.6615 2.00317C36.3115 1.50239 36.9154 1.22923 37.4725 1.13818C38.0296 1.04713 38.494 0.956076 38.9118 0.91055V0.682922H23.2636C22.8457 0.682922 22.6135 0.728448 22.567 0.865025C22.5206 1.0016 22.5206 1.18371 22.5206 1.41133C22.6135 13.2025 22.6135 10.8352 22.6135 25.54Z" fill="black"/>
            <path d="M1.76451 1.95761C1.25375 2.45839 0.835833 3.09575 0.510794 3.91522C0.185755 4.73467 0 5.78177 0 7.10204C0 8.55886 0.464355 9.92463 1.43947 11.1083C2.41462 12.292 3.9005 12.8838 5.8972 12.8838C7.38308 12.8838 8.59039 12.383 9.47263 11.427C10.3549 10.4254 11.005 9.37832 11.4229 8.24016C11.8408 7.10204 12.1194 6.146 12.3051 5.41757C12.4909 4.68918 13.141 4.32494 14.3018 4.32494C14.9055 4.32494 15.602 4.68918 16.4378 5.41757C17.2736 6.146 18.1095 7.19306 18.9917 8.55886C19.874 9.92463 20.6634 11.4725 21.3134 13.2935C21.9635 15.1146 22.3814 17.0267 22.5672 19.0753C22.5672 19.3029 22.5672 19.8037 22.6136 20.5321C22.6136 20.6687 22.6136 20.8053 22.6136 20.9419V15.6609C21.8706 12.6107 20.8491 10.1522 19.5025 8.28569C18.1559 6.37361 17.0415 4.96232 16.1128 4.0518C15.1841 3.14128 13.9768 2.23077 12.398 1.36577C10.8657 0.455259 9.10118 0 7.05804 0C6.12936 0 5.20069 0.136577 4.17913 0.409733C3.29686 0.728412 2.46103 1.2292 1.76451 1.95761Z" fill="black"/>
            <path d="M54.2357 20.8509C54.7466 21.3516 55.1645 21.989 55.4894 22.8085C55.8146 23.628 56.0002 24.675 56.0002 25.9953C56.0002 27.4521 55.5359 28.8179 54.5606 30.0016C53.5856 31.1852 52.0998 31.7771 50.103 31.7771C48.6172 31.7771 47.4097 31.2763 46.5274 30.3202C45.6452 29.3187 44.9951 28.2716 44.5772 27.1334C44.1593 25.9953 43.881 25.0392 43.6949 24.3108C43.5093 23.5824 42.8592 23.2182 41.6985 23.2182C41.0946 23.2182 40.3981 23.5824 39.5623 24.3108C38.7265 25.0392 37.8908 26.0863 37.0085 27.4521C36.1262 28.8179 35.337 30.3658 34.6869 32.1868C34.0368 34.0077 33.6189 35.9199 33.4329 37.9686C33.4329 38.1963 33.4329 38.6969 33.3867 39.4255C33.3867 39.5618 33.3867 39.6985 33.3867 39.8351V34.554C34.1295 31.5039 35.1513 29.0455 36.4976 27.1789C37.8443 25.2669 38.9587 23.8556 39.8875 22.9451C40.8163 22.0345 42.0234 21.124 43.6023 20.259C45.1346 19.3485 46.8992 18.8932 48.9421 18.8932C49.8708 18.8932 50.7996 19.0298 51.8211 19.303C52.7499 19.5761 53.5856 20.1225 54.2357 20.8509Z" fill="black"/>
          </svg>
        </div>
       
      </div>



      <div class="content">
        <div class="links">
        <a href="#concept" @click="closeMenu"> {{$t("menu.concept") }}<span class="underline"></span></a>
        <a href="#cucina-pizza"  @click="closeMenu"
          >{{$t("menu.cucina") }}<span class="underline"></span
        ></a>
        <a href="#esperienze"  @click="closeMenu">{{$t("menu.esperienze") }}<span class="underline"></span></a>
        <a href="#giardino"  @click="closeMenu">{{$t("menu.giardino") }}<span class="underline"></span></a>
        <a href="#contatti"  @click="closeMenu">{{$t("menu.contatti") }}<span class="underline"></span></a>
      </div>
      <div class="social">
        <a class="mx-3" href="https://www.instagram.com/raromodica_/?igshid=YmMyMTA2M2Y%3D" target="_blank">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
            <path d="M17.16 13C17.16 13.8228 16.916 14.6271 16.4589 15.3112C16.0018 15.9953 15.3521 16.5285 14.592 16.8433C13.8318 17.1582 12.9954 17.2406 12.1884 17.0801C11.3815 16.9196 10.6402 16.5233 10.0584 15.9416C9.47665 15.3598 9.08045 14.6185 8.91993 13.8116C8.75942 13.0046 8.8418 12.1682 9.15666 11.408C9.47152 10.6479 10.0047 9.99819 10.6888 9.54109C11.3729 9.08398 12.1772 8.84 13 8.84C14.1022 8.84343 15.1584 9.28281 15.9378 10.0622C16.7172 10.8416 17.1566 11.8978 17.16 13ZM26 7.28V18.72C26 20.6508 25.233 22.5025 23.8677 23.8677C22.5025 25.233 20.6508 26 18.72 26H7.28C5.34922 26 3.49753 25.233 2.13226 23.8677C0.766998 22.5025 0 20.6508 0 18.72V7.28C0 5.34922 0.766998 3.49753 2.13226 2.13226C3.49753 0.766998 5.34922 0 7.28 0H18.72C20.6508 0 22.5025 0.766998 23.8677 2.13226C25.233 3.49753 26 5.34922 26 7.28ZM19.24 13C19.24 11.7658 18.874 10.5594 18.1884 9.53324C17.5027 8.50708 16.5282 7.70728 15.3879 7.23499C14.2477 6.7627 12.9931 6.63913 11.7826 6.8799C10.5722 7.12067 9.46033 7.71497 8.58765 8.58765C7.71497 9.46033 7.12067 10.5722 6.8799 11.7826C6.63913 12.9931 6.7627 14.2477 7.23499 15.3879C7.70728 16.5282 8.50708 17.5027 9.53324 18.1884C10.5594 18.874 11.7658 19.24 13 19.24C14.655 19.24 16.2421 18.5826 17.4123 17.4123C18.5826 16.2421 19.24 14.655 19.24 13ZM21.32 6.24C21.32 5.93146 21.2285 5.62985 21.0571 5.37331C20.8857 5.11677 20.642 4.91682 20.357 4.79875C20.0719 4.68067 19.7583 4.64978 19.4557 4.70997C19.153 4.77017 18.8751 4.91874 18.6569 5.13691C18.4387 5.35508 18.2902 5.63305 18.23 5.93566C18.1698 6.23827 18.2007 6.55193 18.3187 6.83699C18.4368 7.12204 18.6368 7.36568 18.8933 7.53709C19.1499 7.70851 19.4515 7.8 19.76 7.8C20.1737 7.8 20.5705 7.63564 20.8631 7.34309C21.1556 7.05053 21.32 6.65374 21.32 6.24Z" fill="black"/>
          </svg>

        </a>
        <a class="mx-3" href="https://www.facebook.com/profile.php?id=100068232103706" target="_blank">
          <svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.1 14.95H12.35L13.65 9.75H9.1V7.15C9.1 5.811 9.1 4.55 11.7 4.55H13.65V0.182C13.2262 0.1261 11.6259 0 9.9359 0C6.4064 0 3.9 2.1541 3.9 6.11V9.75H0V14.95H3.9V26H9.1V14.95Z" fill="black"/>
          </svg>

        </a>
      </div>
      </div>
         
    </div>

    <div class="row mx-3">
      <div class="col-6">
        <button class="hamb mt-3" @click="openMenu"><img src="../assets/img/hamb.svg" alt="menu button" draggable="false"></button>
      </div>
      <div class="col-6 lang text-end mt-3">
        <button @click="toEng()">EN</button><span class="mx-2">/</span><button @click="toIta()">IT</button>
      </div>
      <div class="logo text-center">
        <img src="../assets/img/logo.svg" alt="Raro logo" draggable="false" />
      </div>
      <div class="links-desk d-flex justify-content-around mt-5 mb-3 " >
        <a href="#concept"> {{$t("menu.concept") }}<span class="underline"></span></a>
        <a href="#cucina-pizza"
          >{{$t("menu.cucina") }}<span class="underline"></span
        ></a>
        <a href="#esperienze">{{$t("menu.esperienze") }}<span class="underline"></span></a>
        <a href="#giardino">{{$t("menu.giardino") }}<span class="underline"></span></a>
        <a href="#contatti">{{$t("menu.contatti") }}<span class="underline"></span></a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    const lang = localStorage.getItem("lang");
    return {
      langBool: false,
      lang: lang,
      isMenuOpen: false,
    };
  },
  mounted() {
    this.detectLang();
  },
  methods: {

    openMenu(){
      this.isMenuOpen = true;
      document.querySelector("html").style.overflow= "hidden"
    },
    closeMenu(){
      this.isMenuOpen = false;  
      document.querySelector("html").style.overflow= "scroll"
    },


    toEng() {
      localStorage.setItem("lang", "en");
      window.location.reload();
    },
    toIta() {
      localStorage.setItem("lang", "it");
      window.location.reload();
    },


    detectLang() {
      let userLang = navigator.language || navigator.userLanguage;
      console.log("The language is: " + userLang);
      if (userLang === "it-IT") {
        localStorage.setItem("lang", "it");
      } else {
        localStorage.setItem("lang", "en");
      }
    },
  },
};
</script>