<template>
  <div class="concept-slider">
    <carousel  
    :navigationEnabled="false"
    :loop="true"
    :paginationEnabled="true"
    :perPageCustom="[[320,1],[1440, 2.5],]"
    >
      <slide class="slide" v-for="(concept, index) in concepts" :key="index">
        <img
          :src="`https://raromodica.it/contents/${concept.foto}`"
          alt="concept photo"
          draggable="false"
        />
      </slide>

    </carousel>
  </div>
</template>

<script>

import { Carousel, Slide } from 'vue-carousel';


export default {
  name: "ConceptSlider",
  components: {
     Carousel,
    Slide
  },

  data() {
    return {
      concepts:[],
    };
  },

  async mounted() {
    let data = await fetch(
      "https://raromodica.it/api/concept-slideshow"
    );
    let concepts = await data.json()

    this.concepts = concepts;
  }
};
</script>

<style>
</style>