<template>
  <section class="esperienze-gallery">
    <div class="esp-modal" id="modal" v-if="espOpen.titolo">
      <div class="row">
        <div class="col-6 black-box" data-aos="fade-right"
        data-aos-anchor="#modal"
        data-aos-delay="160"
        data-aos-duration="1000">
          <h1>{{ espOpen.titolo }}</h1>
        </div>
        <div class="col-6 text-end">
          <button @click="closeModal">
            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M31 61.5C14.1553 61.5 0.500002 47.8447 0.500002 31C0.500003 14.1553 14.1553 0.499998 31 0.499999C47.8447 0.499999 61.5 14.1553 61.5 31C61.5 47.8447 47.8447 61.5 31 61.5Z" stroke="black"/>
              <path d="M37.5 25L25 37.5M37.5 25L25 37.5" stroke="black"/>
              <path d="M25 25L37.5 37.5M25 25L37.5 37.5" stroke="black"/>
            </svg>
          </button>
        </div>
      </div>
      
      <div class="row img-cnt"
        data-aos-anchor="#modal"
        data-aos-delay="200"
        data-aos-duration="1000"
      >

        <carousel :navigationEnabled="false" class="esp-carousel"  :perPage="1">
          <slide class="slide-esp">
            <img v-if="!espOpen.isVideo" :src="`https://raromodica.it/contents/${espOpen.elemento_1}`" alt="foto">
             <video v-if="espOpen.isVideo" class="esp-video"
              preload="auto"
              loop
              autoplay
              
              defaultMuted
              playsinline
              controls
              >
              <source  :src="`https://raromodica.it/contents/${espOpen.elemento_1}`" />
             </video>
          </slide>
          <slide v-if="espOpen.elemento_2" class="slide-esp">
            <img v-if="!espOpen.isVideo" :src="`https://raromodica.it/contents/${espOpen.elemento_2}`" alt="foto">
          </slide>
          <slide v-if="espOpen.elemento_3" class="slide-esp">
            <img v-if="!espOpen.isVideo" :src="`https://raromodica.it/contents/${espOpen.elemento_3}`" alt="foto">
          </slide>
          <slide v-if="espOpen.elemento_4" class="slide-esp">
            <img v-if="!espOpen.isVideo" :src="`https://raromodica.it/contents/${espOpen.elemento_4}`" alt="foto">
          </slide>
        </carousel>
      </div>
    </div>
    <div class="row">
      <div @click="openModal(esperienza)"
        class="col-sm-12 col-lg-6 box"
        v-for="(esperienza, index) in esperienze"
        :key="index"
      >
        <h1>{{ esperienza.titolo }}</h1>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: "EsperienzeGallery",

    components: {
    Carousel,
    Slide
  },


  data() {
    return {
      isModalOpen: false,
      esperienze:[],
      espOpen:{},
    };
  },

  methods:{
    openModal(esperienza){
      this.espOpen = esperienza;
      this.isModalOpen = true;
      document.querySelector("html").style.overflow= "hidden"
    },
    closeModal(){
      this.espOpen = false;
      console.log(this.isModalOpen);
      document.querySelector("html").style.overflow = "scroll"
    }
  },

    async mounted() {
    let data = await fetch(
      "https://raromodica.it/api/esperienze"
    );
    let esperienze = await data.json()

    

      let els = 4
      for(let esp of esperienze)
        for(let i = 1; i <= els; i++) {
          let file = esp['elemento_'+i]
          if(file){
          if(file.indexOf('png') !== -1 || file.indexOf('jpg') !== -1 || file.indexOf('gif') !== -1 || file.indexOf('jpeg') !== -1) {
        esp.isVideo = false
      } else {
        esp.isVideo = true
      }}
    }

    this.esperienze = esperienze;
    console.log(esperienze);
   
  }

};
</script>

<style>
</style>