<template>
  <section class="cucinapics">
    <div class="row" >
      <div class="pic-cnt pic-general" id="pic-uno"
     data-aos="flip-up"
          data-aos-anchor="#pic-uno"
          data-aos-delay="400"
          data-aos-duration="1000"
      >
        <img
          src="../assets/img/cucina_prima.jpg"
          alt="photo"
          draggable="false"
          
        />
      </div>
    </div>
    <div class="row" id="pic-due">
      <div class="pic-cnt pic-risto"
      data-aos="flip-up"
          data-aos-anchor="#pic-due"
          data-aos-delay="400"
          data-aos-duration="900"
      >
        <h1>{{$t("cucinapics.rist") }}</h1>
        <img
          src="../assets/img/ristorante.jpg"
          alt="photo"
          draggable="false"
          
        />
      </div>

      <div class="pic-cnt pic-ape" id="pic-3"
      data-aos="flip-up"
          data-aos-anchor="#pic-3"
          data-aos-delay="400"
          data-aos-duration="900"
      >
        <h1>{{$t("cucinapics.ape") }}</h1>
        <img
          src="../assets/img/aperitivi.jpg"
          alt="photo"
          draggable="false"
          
        />
      </div>
    </div>

    <div class="row" >
      <div class="pic-cnt pic-pizza" id="pic-4"
          data-aos="flip-up"
          data-aos-anchor="#pic-4"
          data-aos-delay="400"
          data-aos-duration="900"
      >
        <h1>{{$t("cucinapics.pizza") }}</h1>
        <img
          src="../assets/img/pizzeria.jpg"
          alt="photo"
          draggable="false"
          
        />
      </div>
    </div>
    <div class="row" >
      <div class="pic-cnt pic-shop" id="pic-5"
          data-aos="flip-up"
          data-aos-anchor="#pic-5"
          data-aos-delay="400"
          data-aos-duration="900"   
      >
        <h1>{{$t("cucinapics.bout") }}</h1>
        <img
          src="../assets/img/boutique.jpg"
          alt="photo"
          draggable="false"
          
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CucinaPics",
};
</script>

<style>
</style>