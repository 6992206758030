<template>
  <div class="landing container-fluid p-0">
    <Header />
    <!-- <<<< SEZIONE VIDEO >>>> -->
    <section class="video-cnt">
      <video preload="auto" muted defaultMuted playsinline autoplay loop>
        <source src="../assets/videos/vhome_comp.mp4" type="video/mp4" />
        <source src="../assets/videos/vhome_comp.webm" type="video/webm" />
        <source src="../assets/videos/vhome_small.ogg" type="video/ogg" />
      </video>
    </section>
    <!-- <<<< SEZIONE CONCEPT >>>> -->
    <section
      id="concept"
      class="text-section"
      data-aos="fade-up"
      data-aos-anchor="#concept"
      data-aos-delay="160"
      data-aos-duration="1000"
    >
      <div class="row">
        <div class="col-1 col-md-2 col-lg-3"></div>
        <div class="col">
          <h1 class="title">{{ $t("concept.title") }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-1 col-md-2 col-lg-5"></div>
        <div class="col-8 col-lg-4">
          <p v-html="$t('concept.text')"></p>
        </div>
      </div>
    </section>
    <ConceptSlider />
    <!-- <<<< SEZIONE CUCINA >>>> -->
    <section
      id="cucina-pizza"
      class="text-section"
      data-aos="fade-right"
      data-aos-anchor="#cucina-pizza"
      data-aos-delay="160"
      data-aos-duration="1000"
    >
      <div class="row">
        <div class="col-1 col-md-2 col-lg-1"></div>
        <div class="col">
          <h1 class="title" v-html="$t('pizza.title')"></h1>
        </div>
      </div>
      <div class="row">
        <div class="col-1 col-md-2 col-lg-1"></div>
        <div class="col-8 col-lg-4">
          <p>
            {{ $t("pizza.text") }}
          </p>
        </div>
      </div>
    </section>
    <CucinaPics class="showDesk" />
    <section class="showMobile mobile-cucina-pic">
      <img src="../assets/img/cucina_prima.jpg" alt="foto" draggable="false" />
    </section>
    <div class="cucina-titles-mobile showMobile">
      <div class="row">
        <div class="titles">
          <h1>/{{ $t("cucinapics.rist") }}</h1>
          <h1>/{{ $t("cucinapics.pizza") }}</h1>
          <h1>/{{ $t("cucinapics.ape") }}</h1>
          <h1>/{{ $t("cucinapics.bout") }}</h1>
        </div>
      </div>
    </div>
    <!-- <<<< SEZIONE ESPERIENZE BIG SLIDER >>>> -->
    <EsperienzeBigSlider />
    <!-- <<<< SEZIONE ESPERIENZE >>>> -->
    <section
      id="esperienze"
      class="text-section"
      data-aos="fade-right"
      data-aos-anchor="#esperienze"
      data-aos-delay="160"
      data-aos-duration="1000"
    >
      <div class="row">
        <div class="col-1 col-md-2 col-lg-1"></div>
        <div class="col">
          <h1 class="title">{{ $t("esp.title") }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-1 col-md-2 col-lg-1"></div>
        <div class="col-8 col-lg-4">
          <p>
            {{ $t("esp.text") }}
          </p>
        </div>
      </div>
    </section>
    <EsperienzeGallery />
    <section class="giardino-foto showDesk">
      <GiardinoSlideshow />
    </section>
    <!-- <<<< SEZIONE GIARDINO >>>> -->
    <section
      id="giardino"
      class="text-section"
      data-aos="fade-up"
      data-aos-anchor="#giardino"
      data-aos-delay="160"
      data-aos-duration="1000"
    >
      <div class="row">
        <div class="col-lg-0"></div>
        <div class="col-lg-12">
          <h1 class="title">{{ $t("giardino.title") }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-0"></div>
        <div class="col-lg-12">
          <p>
            {{ $t("giardino.text") }}
          </p>
        </div>
      </div>
    </section>
    <div class="tags">
      <p>SICILIAN DRINK / VERTICAL GARDEN / LIFESTYLE</p>
    </div>
    <!-- <<<< SEZIONE FOOTER >>>> -->
    <section class="giardino-foto showMobile">
      <GiardinoSlideshow />
    </section>
    <footer id="contatti">
      <div class="row">
        <div class="col-xl-6 info">
          <img
            src="../assets/img/logo_ext.svg"
            alt="raro logo"
            draggable="false"
          />
          <div class="orari">
            <p>{{ $t("orari.lun") }}: 17:00 - 23:00</p>
            <p>{{ $t("orari.mar") }}: 17:00 - 23:00</p>
            <p>{{ $t("orari.mer") }}: 17:00 - 23:00</p>
            <p>{{ $t("orari.gio") }}: 17:00 - 23:00</p>
            <p>{{ $t("orari.ven") }}: 17:00 - 23:00</p>
            <p>{{ $t("orari.sab") }}: 17:00 - 23:00</p>
            <p>{{ $t("orari.dom") }}: 17:00 - 23:00</p>
          </div>
          <div class="location">
            <p>VIA SAN BENEDETTO DA NORCIA, 6997015 - MODICA, RG</p>
            <a href="tel:0932 764094">0932 764094</a>
            <a href="tel:331 9515301">331 9515301</a>
            <a href="mailto:info@raromodica.it"
              >INFO@RAROMODICA.IT<span class="underline"></span
            ></a>
          </div>
          <div class="social">
            <a
              href="https://www.instagram.com/raromodica_/?igshid=YmMyMTA2M2Y%3D"
              target="_blank"
            >
              <img
                src="../assets/img/icon_ig.svg"
                alt="instagram"
                draggable="false"
              />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100068232103706"
              target="_blank"
            >
              <img
                src="../assets/img/icon_fb.svg"
                alt="facebook"
                draggable="false"
              />
            </a>
          </div>
        </div>
        <div class="col-12 col-xl-6 maps-cnt">
          <iframe
            style="filter: grayscale(100%)"
            width="100%"
            height="100%"
            src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Via%20San%20Benedetto%20da%20Norcia%2C%2069%20Modica+(RARO)&amp;ie=UTF8&amp;t=&amp;z=15&amp;iwloc=B&amp;output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import EsperienzeBigSlider from "@/components/EsperienzeBigSlider.vue";
import ConceptSlider from "@/components/ConceptSlider.vue";
import EsperienzeGallery from "@/components/EsperienzeGallery.vue";
import CucinaPics from "@/components/CucinaPics.vue";
import GiardinoSlideshow from "@/components/GiardinoSlideshow.vue";

export default {
  name: "Home",
  components: {
    Header,
    EsperienzeBigSlider,
    ConceptSlider,
    EsperienzeGallery,
    CucinaPics,
    GiardinoSlideshow,
  },

  data() {
    return {
      orari: [],
    };
  },

  async mounted() {
    let data = await fetch("https://raromodica.it/api/orari");
    let orari = await data.json();

    this.orari = orari;
  },
};
</script>
