<template>
  <div class="giardino-slider">
    <carousel  
    :navigationEnabled="false"
    :loop="true"
    :paginationEnabled="false"
    :perPage="1"
    :autoplay="true"
    >
      <slide class="slide" v-for="(foto, index) in giardinopics" :key="index">
        <img
          :src="`https://raromodica.it/contents/${foto.foto}`"
          alt="giardino photo"
          draggable="false"
        />
      </slide>

    </carousel>
  </div>
</template>

<script>

import { Carousel, Slide } from 'vue-carousel';


export default {
  name: "GiardinoSlider",
  components: {
     Carousel,
    Slide
  },

  data() {
    return {
      giardinopics:[],
    };
  },

  async mounted() {
    let data = await fetch(
      "https://raromodica.it/api/giardino-slideshow"
    );
    let giardinopics = await data.json()

    this.giardinopics = giardinopics;
  }
};
</script>

<style>
</style>