<template>
  <div class="esp-big-slider">
    <agile :dots="false" :navButtons="false" ref="carousel" v-if="locationpics.length > 0">
      <div class="slide" v-for="(foto, index) in locationpics" :key="index">
        <img
          :src="`https://raromodica.it/contents/${foto.foto}`"
          alt="giardino photo"
          draggable="false"
        />
      </div>
    </agile>
    <div class="navigation mt-5">
      <button @click="$refs.carousel.goToPrev()" class="me-5">
        <img src="../assets/img/prev-btn.svg" alt="prev" draggable="false" />
      </button>
      <button @click="$refs.carousel.goToNext()" class="ms-5">
        <img src="../assets/img/next-btn.svg" alt="next" draggable="false" />
      </button>
    </div>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";

export default {
  name: "EsperienzeBigSlider",
  components: {
    agile: VueAgile,
  },

  data() {
    return {
      locationpics: [],
    };
  },

  async mounted() {
    let data = await fetch("https://raromodica.it/api/location-slideshow");
    let locationpics = await data.json();

    this.locationpics = locationpics;
  },
};
</script>

<style>
</style>